// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { capitalize, keys, words } from 'lodash-es'
import PropTypes from 'prop-types'
import moment from 'moment'
import qs from 'query-string'

import * as structureTypes from 'syft-acp-core/components/EditableTable'
import { SUPPORTED_COUNTRY_CODES } from 'syft-acp-core/lib/i18n'
import { formatFullName, formatWorkerLink, formatWorkerMyFlexLink } from 'syft-acp-util/formatting'
import { WorkerStatusTierRewards } from 'syft-acp-core/store/workerTier/types'

import { FormattedGeolocationData } from './WorkerDetail.types'

export const dataPropTypes = PropTypes.shape({
  id: PropTypes.number,
  address: PropTypes.shape({
    line_1: PropTypes.string,
    line_2: PropTypes.string,
    city: PropTypes.string,
    post_code: PropTypes.string,
  }),
  bank_account: PropTypes.shape({
    account_number: PropTypes.string,
    sort_code: PropTypes.string,
  }),
  bio: PropTypes.string,
  complete: PropTypes.bool,
  date_of_birth: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  full_legal_name: PropTypes.string,
  interested_in_fulltime: PropTypes.bool,
  name: PropTypes.string,
  national_insurance_number: PropTypes.string,
  telephone_number: PropTypes.string,
  profile_picture: PropTypes.shape({
    uuid: PropTypes.string,
  }),
  minimum_rate: PropTypes.shape({
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    currency: PropTypes.string,
  }),
  worker_roles: PropTypes.arrayOf(
    PropTypes.shape({
      average_rating: PropTypes.number.isRequired,
      verified: PropTypes.bool.isRequired,
      role: PropTypes.shape({
        code: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
      }),
    }),
  ),
  worker_next_of_kin: PropTypes.shape({
    name: PropTypes.string,
    relationship: PropTypes.string,
    telephone_number: PropTypes.string,
  }),
})

export const dataStructure = {
  bio: [
    ['id', { header: 'ID' }],
    ['name', { header: 'Name', calc: (e: { first_name: string; last_name: string }) => formatFullName(e) }],
    ['first_name', { header: 'First name', editable: true }],
    ['last_name', { header: 'Last name', editable: true }],
    ['full_legal_name', { header: 'Full legal name', editable: true }],
    ['telephone_number', { header: 'Tel.', editable: true, type: structureTypes.TYPE_PHONE_NUMBER }],
    ['email', { header: 'Email', editable: false }],
    [
      'created_at',
      {
        header: 'Created on',
        type: structureTypes.TYPE_TIMESTAMP,
      },
    ],
    [
      'first_verified_at',
      {
        header: 'First verified at',
        type: structureTypes.TYPE_TIMESTAMP,
      },
    ],
    [
      'last_active_at',
      {
        header: 'Last active',
      },
    ],
    [
      'status_program.tier.name',
      {
        header: 'MyFlex Level',
        calc: ({
          status_program,
          id,
          country,
        }: {
          status_program: WorkerStatusTierRewards
          id: number
          country: 'UK' | 'US'
        }) => formatWorkerMyFlexLink(status_program?.tier?.name, status_program?.tier?.code, id, country),
      },
    ],
    [
      'status_program.tier.last_updated_at',
      {
        header: 'My Flex Level last updated',
        type: structureTypes.TYPE_TIMESTAMP,
      },
    ],
    [
      'status_program.rewards_tier.name',
      {
        header: 'MyFlex Rewards',
        calc: ({
          status_program,
          id,
          country,
        }: {
          status_program: WorkerStatusTierRewards
          id: number
          country: 'UK' | 'US'
        }) =>
          formatWorkerMyFlexLink(
            status_program?.rewards_tier?.name,
            status_program?.rewards_tier?.code,
            id,
            country,
          ),
      },
    ],
    [
      'user_id',
      {
        header: 'User ID',
        editable: false,
        calc: (e: { user_id: number }) => formatWorkerLink(e),
      },
    ],
    [
      'latest_os',
      {
        header: 'Last OS used',
        editable: false,
      },
    ],
    [
      'average_rating',
      {
        header: 'Avg. rating',
        type: structureTypes.TYPE_RATING,
      },
    ],
    [
      'trusted',
      {
        header: 'Trusted',
        editable: true,
        type: structureTypes.TYPE_CHECKBOX,
      },
    ],
    [
      'interested_in_fulltime',
      {
        header: 'Interested in full time',
        editable: true,
        type: structureTypes.TYPE_CHECKBOX,
      },
    ],
    [
      'worker_availability',
      {
        header: 'Worker Availability',
        type: structureTypes.TYPE_AVAILABILITY,
        editable: true,
      },
    ],
    [
      'opt_out_48hrs',
      {
        header: 'Can work ≥48 hrs/wk',
        type: structureTypes.TYPE_CHECKBOX,
        editable: true,
        hideForCountries: [SUPPORTED_COUNTRY_CODES.US],
      },
    ],
    [
      'p45_enabled',
      {
        header: 'P45 enabled',
        editable: true,
        type: structureTypes.TYPE_CHECKBOX,
        hideForCountries: [SUPPORTED_COUNTRY_CODES.US],
      },
    ],
    [
      'p45_enabled_at',
      {
        header: 'P45 enabled at',
        type: structureTypes.TYPE_DATE,
        hideForCountries: [SUPPORTED_COUNTRY_CODES.US],
      },
    ],
    [
      'prefer_public_transport',
      {
        header: 'Would you prefer shifts that were easily accessible using public transport?',
        type: structureTypes.TYPE_CHECKBOX,
        editable: true,
      },
    ],
    [
      'have_access_to_car',
      {
        header: 'Do you have access to a car?',
        type: structureTypes.TYPE_CHECKBOX,
        editable: true,
      },
    ],
  ],
  drivingLicense: [
    [
      'driver_license_type',
      {
        header: 'Licence type',
        hasUpload: true,
        uploadType: 'driving-licence',
        editable: true,
        options: [
          { value: '', acceptNull: true, defaultValue: true, label: 'None' },
          { value: 'uk-full', label: 'UK Full Driving Licence' },
          { value: 'uk-provisional', label: 'UK Provisional Driving Licence' },
          // Note: Syft only accepts EU driving licences aside from UK ones.
          // That's why it's named this despite being 'foreign' in the database.
          { value: 'foreign', label: 'EU Driving Licence' },
        ],
        type: structureTypes.TYPE_ENUM,
        showForCountries: [SUPPORTED_COUNTRY_CODES.GB],
      },
    ],
    [
      'driver_license_type',
      {
        header: 'Licence type',
        hasUpload: true,
        uploadType: 'driving-licence',
        editable: true,
        options: [
          { value: '', acceptNull: true, defaultValue: true, label: 'None' },
          { value: 'us-full', label: 'Driving Licence' },
        ],
        type: structureTypes.TYPE_ENUM,
        showForCountries: [SUPPORTED_COUNTRY_CODES.US],
      },
    ],
    [
      'driver_license_check_certificate_expiry_date',
      {
        hasUpload: true,
        uploadType: 'driving-licence-check-certificate',
        header: 'License check expiry',
        editable: true,
        type: structureTypes.TYPE_DATE,
        showForCountries: [SUPPORTED_COUNTRY_CODES.GB],
      },
    ],
    ['driver_license_post_code', { marginRight: true, editable: true, translationKey: 'postcode' }],
    ['driver_license_number', { marginRight: true, header: 'Driving licence number', editable: true }],
    [
      'driver_license_expiry_date',
      { marginRight: true, header: 'Expiry date', editable: true, type: structureTypes.TYPE_DATE },
    ],
    [
      'penalty_points',
      { marginRight: true, header: 'Penalty points', editable: true, type: structureTypes.TYPE_NUMBER },
    ],
  ],
  interview: [
    [
      'interviewed_on',
      {
        header: 'Interviewed on',
        editable: true,
        parameters: { autoDate: true, disableFuture: true },
        type: structureTypes.TYPE_DATE,
      },
    ],
    [
      'interviewer.email',
      {
        header: 'Interviewed by',
        editable: true,
        parameters: {
          fallbackDefault: true,
          highlightDefault: true,
          type: 'email',
        },
      },
    ],
    [
      'worker_signup_applied_role',
      {
        header: 'Client / Venue',
        editable: true,
        type: structureTypes.TYPE_EMPLOYER_VENUE,
      },
    ],
    [
      'worker_signup_applied_role.role_id',
      {
        header: 'Original Application Role',
        editable: true,
        type: structureTypes.TYPE_ROLE,
      },
    ],
    [
      'training_details',
      {
        header: 'Induction notes',
        editable: true,
        type: structureTypes.TYPE_INFINITE_TEXTAREA,
      },
    ],
    [
      'save_interview',
      {
        header: '',
        type: structureTypes.TYPE_SAVE_BUTTON,
      },
    ],
  ],
  compliance: [
    [
      'worker_signup_applied_role',
      {
        header: 'Client / Venue',
        editable: true,
        type: structureTypes.TYPE_EMPLOYER_VENUE,
      },
    ],
    [
      'worker_signup_applied_role.role_id',
      {
        header: 'Original Application Role',
        editable: true,
        type: structureTypes.TYPE_ROLE,
      },
    ],
    [
      'training_details',
      {
        header: 'Notes',
        editable: true,
        type: structureTypes.TYPE_INFINITE_TEXTAREA,
      },
    ],
    [
      'save_interview',
      {
        header: '',
        type: structureTypes.TYPE_SAVE_BUTTON,
      },
    ],
  ],
  visa: [
    ['first_name', { header: 'First name', editable: false }],
    ['last_name', { header: 'Last name', editable: false }],
    ['full_legal_name', { header: 'Full legal name', editable: false }],
    [
      'date_of_birth',
      {
        header: 'Date of birth',
        editable: false,
        type: structureTypes.TYPE_DATE_MIN_AGE,
      },
    ],
    [
      'eea_national_id',
      {
        header: 'EEA National ID document',
        hasUpload: true,
        uploadType: 'national-id',
        canLoadDocuments: true,
        editable: false,
        options: [],
        type: structureTypes.TYPE_ENUM,
        showForCountries: [SUPPORTED_COUNTRY_CODES.GB],
      },
    ],
    [
      'passport',
      {
        header: 'Passport document',
        hasUpload: true,
        uploadType: 'passport',
        canLoadDocuments: true,
        editable: false,
        options: [],
        type: structureTypes.TYPE_ENUM,
        showForCountries: [SUPPORTED_COUNTRY_CODES.GB],
      },
    ],
    [
      'document_country',
      {
        header: 'Document country',
        marginRight: true,
        editable: false,
        type: structureTypes.TYPE_TXT,
      },
    ],
    [
      'document_expiry_date',
      {
        header: 'Document expiry',
        marginRight: true,
        editable: false,
        type: structureTypes.TYPE_DATE,
      },
    ],
    [
      'ilr',
      {
        header: 'ILR',
        editable: false,
        marginRight: true,
        type: structureTypes.TYPE_CHECKBOX,
        showForCountries: [SUPPORTED_COUNTRY_CODES.GB],
      },
    ],
    [
      'pss',
      {
        header: 'PSS',
        editable: false,
        marginRight: true,
        type: structureTypes.TYPE_CHECKBOX,
        showForCountries: [SUPPORTED_COUNTRY_CODES.GB],
      },
    ],
    [
      'visa_expired_on',
      {
        header: 'Visa expiry',
        hasUpload: true,
        uploadType: 'visa',
        editable: false,
        // See <CMS2-583>.
        triggers: [{ on: 'ilr', from: 'document_expiry_date', type: 'copy' }],
        type: structureTypes.TYPE_DATE,
      },
    ],
    [
      'national_insurance_number',
      {
        header: 'NI number',
        editable: false,
        hasUpload: true,
        uploadType: 'ni',
        showForCountries: [SUPPORTED_COUNTRY_CODES.GB],
      },
    ],
    [
      'uk_birth_certificate',
      {
        header: 'UK birth certificate',
        hasUpload: true,
        uploadType: 'birth_certificate',
        editable: false,
        options: [],
        type: structureTypes.TYPE_ENUM,
        showForCountries: [SUPPORTED_COUNTRY_CODES.GB],
      },
    ],
    [
      'uk_full_drivers_license',
      {
        header: "UK full driver's license",
        editable: false,
        marginRight: true,
        triggers: [
          {
            on: 'driver_license_type',
            type: 'set',
            toTrue: true,
            toFalse: false,
            ifEqual: true,
            compare: 'uk-full',
          },
        ],
        type: structureTypes.TYPE_CHECKBOX,
        showForCountries: [SUPPORTED_COUNTRY_CODES.GB],
      },
    ],
    [
      'deleted_rtw_documents',
      {
        header: 'Deleted documents',
        showForCountries: [SUPPORTED_COUNTRY_CODES.GB],
        type: structureTypes.TYPE_DELETED_RTW_DOCUMENTS_LIST,
      },
    ],
  ],
  profile: [
    ['profile_picture', { header: 'Photo', type: structureTypes.TYPE_IMAGE, editable: true }],
    ['bio', { header: 'Biography', type: structureTypes.TYPE_TEXTAREA, editable: true }],
    [
      'date_of_birth',
      {
        header: 'Date of birth',
        editable: true,
        type: structureTypes.TYPE_DATE_MIN_AGE,
      },
    ],
    ['geo_location', { header: 'Location', editable: true, type: structureTypes.TYPE_COORDS }],
    ['minimum_rate', { header: 'Min. rate', editable: true, type: structureTypes.TYPE_RATE }],
    [
      'years_of_experience',
      {
        header: 'Years of work experience at interview',
        editable: true,
        type: structureTypes.TYPE_NUMBER,
      },
    ],
  ],
  geolocationPreferences: [
    ['coordinates', { header: 'Location', type: structureTypes.TYPE_COORDS }],
    ['radius', { header: 'Location Radius', type: structureTypes.TYPE_NUMBER }],
    [
      'radius_unit',
      {
        header: 'Radius Unit',
        options: [
          { value: 'km', defaultValue: true, label: 'kilometres' },
          { value: 'mi', label: 'miles' },
        ],
        type: structureTypes.TYPE_ENUM,
      },
    ],
    [
      'shifts_within_radius',
      {
        header: 'Shifts Within Radius',
        parameters: {
          to: (data: FormattedGeolocationData) =>
            data.coordinates &&
            `/shifts/flex?longitude=${data.coordinates.longitude}&latitude=${
              data.coordinates.latitude
            }&radius=${data.radius}&radius_unit=${
              data.radius_unit
            }&filled=false&offer_to=&start_time_gte=${moment.utc().format()}&start_time_lte=${moment()
              .add(1, 'month')
              .format()}&${qs.stringify(
              {
                role_id: data.role_id,
              },
              { arrayFormat: 'none' },
            )}`,
        },
        type: structureTypes.TYPE_LINK,
      },
    ],
  ],
  admin: [
    ['address.line_1', { header: 'Address line 1', editable: true }],
    ['address.line_2', { header: 'Address line 2', editable: true }],
    ['address.post_code', { editable: true, translationKey: 'postcode' }],
    ['address.city', { header: 'City', editable: true }],
    [
      'address.federated_state_code',
      {
        header: 'State',
        editable: true,
        type: structureTypes.TYPE_STATE_DROPDOWN,
        showForCountries: [SUPPORTED_COUNTRY_CODES.US],
      },
    ],
    [
      'national_insurance_number',
      {
        header: 'NI number',
        editable: true,
        showForCountries: [SUPPORTED_COUNTRY_CODES.GB],
      },
    ],
  ],
  bank: [
    ['bank_account.first_name', { header: 'First name', editable: false }],
    ['bank_account.last_name', { header: 'Last name', editable: false }],
    ['bank_account.full_name', { header: 'Full name', editable: false }],
    ['bank_account.account_number', { header: 'Account number (8 digits)', editable: false }],
    ['bank_account.sort_code', { header: 'Sort code (NN-NN-NN)', editable: false }],
  ],
  workerNextOfKin: [
    ['worker_next_of_kin.name', { header: 'Name', editable: true }],
    ['worker_next_of_kin.relationship', { header: 'Relationship', editable: true }],
    [
      'worker_next_of_kin.telephone_number',
      { header: 'Tel. number', editable: true, type: structureTypes.TYPE_PHONE_NUMBER },
    ],
  ],
  holidayPay: [
    ['historic.total', { header: 'Total', editable: false, type: structureTypes.TYPE_CURRENCY }],
    [
      'historic.summary_date',
      {
        header: 'Last updated historic holiday pay timestamp',
        editable: false,
        type: structureTypes.TYPE_TIMESTAMP,
      },
    ],
    [
      'weekly.working_week_beginning_on',
      { header: 'Current working week start date', editable: false, type: structureTypes.TYPE_DATE },
    ],
    [
      'weekly.working_week_ending_on',
      { header: 'Current working week end date', editable: false, type: structureTypes.TYPE_DATE },
    ],
    [
      'weekly.requested',
      {
        header: 'Weekly holiday pay request has been made',
        editable: false,
        type: structureTypes.TYPE_CHECKBOX,
      },
    ],
    [
      'weekly.request_cutoff_at',
      {
        header: 'Cut off timestamp for weekly holiday pay request',
        editable: false,
        type: structureTypes.TYPE_TIMESTAMP,
      },
    ],
    ['historic.pending', { header: 'Pending', editable: false, type: structureTypes.TYPE_CURRENCY }],
    ['historic.available', { header: 'Available', editable: false, type: structureTypes.TYPE_CURRENCY }],
    [
      'historic.average_holiday_rate',
      { header: '52 week average holiday pay rate', editable: false, type: structureTypes.TYPE_CURRENCY },
    ],
  ],
  marketingPreferences: [
    [
      'marketing_emails_consent',
      {
        header: 'Marketing emails',
        editable: true,
        type: structureTypes.TYPE_CHECKBOX,
      },
    ],
    [
      'marketing_calls_consent',
      {
        header: 'Marketing calls and texts',
        editable: true,
        type: structureTypes.TYPE_CHECKBOX,
      },
    ],
  ] as const,
}

export const prevExpStructure = [
  ['id', { header: 'ID' }],
  ['_workerID', { header: 'Worker ID' }],
  ['company_name', { header: 'Company name', editable: true }],
  ['desc', { header: 'description', type: structureTypes.TYPE_TEXTAREA, editable: true }],
  ['job_role', { header: 'Job role', editable: true }],
  ['started', { header: 'Started (mm/yyyy)', editable: true }],
  ['ended', { header: 'Ended (mm/yyyy)', editable: true }],
]

export const statsStructure = (object = {}) => {
  const props = {
    average_wage: {
      type: structureTypes.TYPE_CURRENCY,
    },
    total_earnings: {
      type: structureTypes.TYPE_CURRENCY,
    },
    percentage_of_cancelled_shifts: {
      type: structureTypes.TYPE_PERCENT,
    },
    percentage_of_shifts_clocked_in_5mins_after_start: {
      type: structureTypes.TYPE_PERCENT,
    },
  }
  type PropsKeys = keyof typeof props

  // @ts-ignore
  const getProp = (key: PropsKeys, prop: string) => props[key] && props[key][prop]
  const toSentence = (key: string) => words(capitalize(key)).join(' ')

  return (keys(object) as PropsKeys[]).map(key => [
    key,
    {
      header: getProp(key, 'header') || toSentence(key),
      type: getProp(key, 'type'),
    },
  ])
}
