import { get } from 'lodash-es'

import { draftKey } from 'syft-acp-core/reducers/states/listingShiftBookingsStates'

// Occasionally we get empty shift_booking objects. See CMS2-337.
// Remove all bookings whose ID is null or undefined.
// TODO: This may be refactored in the future as part of a larger overhaul of shift bookings.

const removeEmptyBookings = bookings => bookings.filter(b => b.id != null)

export const shiftBookingsList = ({ bookings, shiftID, jobID, timezone }) => {
  const shiftBookingsWithoutEmpty = removeEmptyBookings(bookings).map(booking => ({
    ...booking,
    worker: {
      ...booking.worker,
      originalWorkerId: get(booking, 'worker.id', 0),
    },
    shiftID,
    jobID,
    startTime: get(booking, 'shift.start_time', null),
    endTime: get(booking, 'shift.end_time', null),
    timezone,
  }))
  // Note: we cannot use 'filled_spots' because it does not take agency bookings into account.
  // The 'shift_bookings' array will always contain exactly the number of spots we need to show,
  // and will have fewer items than the difference between 'total_spots' and 'filled_spots'
  // if there are agency bookings.
  const remainSpots = bookings.length - shiftBookingsWithoutEmpty.length

  for (let i = 0; i < remainSpots; i += 1) {
    const blankShiftBooking = {
      // Note: non-breaking space.
      // Added shiftID to draft unique id
      // eslint-disable-next-line no-irregular-whitespace
      id: `${draftKey} ${i + 1} ${shiftID}`,
      worker: {},
      break: { duration: 0 },
      shiftID,
      jobID,
      startTime: get(bookings, `[${shiftBookingsWithoutEmpty?.length}].shift.start_time`, null),
      endTime: get(bookings, `[${shiftBookingsWithoutEmpty?.length}].shift.end_time`, null),
      fee_percent: 0,
      timezone,
    }
    shiftBookingsWithoutEmpty.push(blankShiftBooking)
  }
  return shiftBookingsWithoutEmpty
}
